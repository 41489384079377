import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../Modal';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import AutomobileSelector from "./../AutomobileSelector";

function VehiculeAddManuallyModal(props) {

    const intl = props.intl;

    const [vehicule, setVehicule] = useState({});

    return <Modal display={props.display || false}
        onClose={() => {
        }}>

        <Paper elevation={0}>

            <TextField label="Marque" variant="outlined" sx={{ width: "100%", textAlign: "center" }} name="ref_fab"
                value={vehicule.brand}
                onChange={(event) => {
                    vehicule.brand = event.target.value;
                    setVehicule({ ...vehicule });
                }}
            />

            <br />
            <br />
            <TextField label="Modéle" variant="outlined" sx={{ width: "100%", textAlign: "center" }} name="ref_fab"
                value={vehicule.model}
                onChange={(event) => {
                    vehicule.model = event.target.value;
                    setVehicule({ ...vehicule });
                }}
            />

            <br />
            <br />
            <TextField label="Motorisation" variant="outlined" sx={{ width: "100%", textAlign: "center" }} name="ref_fab"
                value={vehicule.carName}
                onChange={(event) => {
                    vehicule.carName = event.target.value;
                    setVehicule({ ...vehicule });
                }}
            />

            <br />
            <br />
            <AutomobileSelector onClick={async (value) => {
                vehicule.plate = value.plate;
                vehicule.designation = vehicule.brand + " " + vehicule.model + " " + vehicule.carName;
                setVehicule({ ...vehicule });
                props.onValidate && props.onValidate(vehicule);
            }}
            />

            <br />
            <Button
                variant="contained"
                color="error"
                sx={{ width: '100%' }}
                onClick={() => {
                    props.onClose && props.onClose();
                }}
            >{intl.formatMessage({ id: 'vehicule.add.modal.close' })}</Button>
        </Paper>

    </Modal>
}

export default injectIntl(VehiculeAddManuallyModal);